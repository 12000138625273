import {useEffect} from "react";
import {useHistory} from "react-router-dom";

/*
Hook component needed for react router to scroll to top after page transition
 */
export default function ScrollToTop() {

    const history = useHistory();

    useEffect(() => {
        const unlisten = history.listen(() => {
            window.scrollTo({top: 0, behavior: "instant"});
        });
        return () => {
            unlisten();
        }
    }, []);

    return (null);
}