import "./IFrame.css";

export default function IFrame(props) {
    return (
        <div className="frame-container">
            <iframe className="responsive-iframe" ref={props.refC}
                    style={props.style}
                    width={props.width}
                    height={props.width}
                    src={props.src}
                    title={props.title}
                    frameBorder={props.frameBorder}
                    allow={props.allow}
                    allowFullScreen={props.allowFullScreen}/>
        </div>
    )
}