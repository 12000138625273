import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import Navigation from "./component/Navigation/Navigation";
import Landing from "./view/Landing/Landing";
import Footer from "./component/Footer/Footer";
import Jumping from "./view/Jumping/Jumping";
import Events from "./view/Events/Events";
import {BrowserRouter, Route, Switch} from "react-router-dom";
import EventDetails from "./view/EventDetails/EventDetails";
import Art from "./view/Art/Art";
import Fashion from "./view/Fashion/Fashion";
import Griffon from "./view/Griffon/Griffon";
import PvE from "./view/PvE/PvE";
import Beetle from "./view/Beetle/Beetle";
import Music from "./view/Music/Music";
import {Container} from "react-bootstrap";
import ScrollToTop from "./component/ScrollToTop/ScrollToTop";
import AboutUs from "./view/AboutUs/AboutUs";
import TeamMemberDetails from "./view/TeamMember/TeamMemberDetails";
import NotFound from "./view/NotFound/NotFound";

function App() {
  return (
      <BrowserRouter>
        <div className="App">
            <Navigation/>
            <Container fluid style={{padding: 0, paddingBottom: '12rem'}}>
                <ScrollToTop/>
                <Switch>
                    <Route exact path="/">
                        <Landing/>
                    </Route>
                    <Route exact path="/about-us">
                        <AboutUs/>
                    </Route>
                    <Route exact path="/about-us/:id">
                        <TeamMemberDetails/>
                    </Route>
                    <Route path="/categories/art">
                        <Art/>
                    </Route>
                    <Route path="/categories/beetle">
                        <Beetle/>
                    </Route>
                    <Route path="/categories/fashion">
                        <Fashion/>
                    </Route>
                    <Route path="/categories/griffon">
                        <Griffon/>
                    </Route>
                    <Route path="/categories/jumping-puzzles">
                        <Jumping/>
                    </Route>
                    <Route path="/categories/music">
                        <Music/>
                    </Route>
                    <Route path="/categories/pve">
                        <PvE/>
                    </Route>
                    <Route exact path="/events">
                        <Events/>
                    </Route>
                    <Route exact path="/events/:id">
                        <EventDetails/>
                    </Route>
                    <Route path="*">
                        <NotFound/>
                    </Route>
                </Switch>
            </Container>
            <Footer/>
        </div>
      </BrowserRouter>
  );
}

export default App;
