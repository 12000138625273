import './ImageC.css';
import {Image} from "react-bootstrap";
import {useState} from "react";
import ImageModal from "../ImageModal/ImageModal";

interface ImageCProps {
    fluid?: boolean,
    src: string,
    modal?: boolean,
    style?: any
}

export default function ImageC(props: ImageCProps) {

    const [modalData, setModalData] = useState({
        show: false,
        image: props.src
    });

    function onClickModal() {
        if(props.modal) {
            setModalData({
                show: true,
                image: props.src
            })
        }
    }

    function modalStyle() {
        if(props.modal) {
            return {cursor: "pointer"}
        }
        return {}
    }

    return (
        <>
            <Image {...props} onClick={onClickModal} style={{...modalStyle(), ...props.style}}/>
            { props.modal ?
                <ImageModal show={modalData.show}
                onHide={() => setModalData({show: false, image: modalData.image})}
                image={modalData.image}/> : ''
            }
        </>
    )
}