import './ElementSlide.css';
import {Carousel} from "react-bootstrap";

interface CardSlideProps {
    elements: any[];
    height: string;
    interval?: number | null;
    controls?: boolean;
    onSlide?: (...input: any) => void;
}

export default function ElementSlide(props: CardSlideProps) {
    return (
        <Carousel controls={props.controls} style={{height: props.height}} interval={props.interval}
                  onSlide={props.onSlide}>
            {props.elements.map(element =>
                <Carousel.Item>
                    {element}
                </Carousel.Item>
            )}
        </Carousel>
    );
}