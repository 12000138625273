import "./EventList.css"
import {Table} from "react-bootstrap";
import CalenderIcon from "../CalenderIcon/CalenderIcon";
import {Event} from "uaot-api/grpc/uaot/api/v1/event_pb";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";
import {useHistory} from "react-router-dom";

interface EventListEntryProps {
    event: Event;
}

function EventListEntry(props: EventListEntryProps) {
    let history = useHistory();

    function openEventDetail(id: string): void {
        history.push(`/events/${id}`);
    }


    function validateTimestamp(date?: Timestamp): Date {
        if(date) {
            return date.toDate();
        }
        return new Date();
    }

    return (
        <tr className={"selectable"} onClick={() => openEventDetail(props.event.getId())}>
            <td><CalenderIcon size={"m"} date={validateTimestamp(props.event.getStartingAt())} /></td>
            <td>{props.event.getName()}</td>
            <td>{props.event.getLocation()}</td>
        </tr>
    )
}

interface EventListProps {
    events: Event[];
}

export function EventList(props: EventListProps) {
    return (
        <Table hover>
            <tbody className={"event-list"}>
                {props.events.map((e, i) =>
                    <EventListEntry event={e} key={e.getId()}/>
                )}
            </tbody>
        </Table>
    )
}