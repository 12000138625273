// source: uaot/api/v1/rpc.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {missingRequire} reports error on implicit type usages.
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */
// GENERATED CODE -- DO NOT EDIT!
/* eslint-disable */
// @ts-nocheck

var jspb = require('google-protobuf');
var goog = jspb;
var global = Function('return this')();

var uaot_options_pb = require('../../../uaot/options_pb.js');
goog.object.extend(proto, uaot_options_pb);
var uaot_api_v1_requests_pb = require('../../../uaot/api/v1/requests_pb.js');
goog.object.extend(proto, uaot_api_v1_requests_pb);
var uaot_api_v1_responses_pb = require('../../../uaot/api/v1/responses_pb.js');
goog.object.extend(proto, uaot_api_v1_responses_pb);
