import "./PvE.css";
import "./../../App.css";
import {Col, Container, Image, Row} from "react-bootstrap";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import pve1 from "../../media/pve/pve1.jpg";
import pve2 from "../../media/pve/pve2.jpg";
import pve3 from "../../media/pve/pve3.jpg";
import pve4 from "../../media/pve/pve4.jpg";
import pveTitle from "../../media/titles/openworld.png";

export default function PvE() {
    return (
        <Container fluid style={{padding: 0}}>
            <RowBackground title img={pve1} borderBottom>
                <h1>OPEN WORLD</h1>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Niche communities play the actual game</h3>
                                Although the main focus of our alliance is to spotlight the alternative and for the most
                                part custom made end game content of our affiliated guilds, we also want to offer those
                                communities the opportunity to mingle and learn the core concepts of the game together.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={pve2} borderBottom>
                <Container>
                    <Row>
                        <Col md={12}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Teaming up with experts</h3>
                                This is the reason why we teamed up with PvE guilds and communities and are actively
                                looking for our very own commanders that create a non-toxic environment for Raids,
                                Strikes, Fractals and any open world PvE content. With them you can also master/improve
                                your class or join on regular clears or farm trains. We offer a collective of commanders
                                who are very casual-friendly and who put a lot of time and patience into their field to
                                make it a very comfortable place for newcomers, people who dislike LFGs or people who
                                experienced communities where they were not treated right. They focus on teaching people
                                individually, or as a group to the core of their rotation if needed and make sure you
                                understand your class for every mechanic possible if you wish.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Raids</h3>
                                Our commanders are considerate of suggestions and wishes of the community. If there is
                                an achievement or a CM that you need to get done, let them know and they will try to
                                organize a squad and help you. On our Discord we have a Raider role to get notified for
                                clears and trainings. Commanders also organize casual Wing clears with experienced
                                people regularly.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={pve3} borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Strikes</h3>
                                With new strike missions and their CMs in End of Dragons, we added those as separate PvE
                                topic and will organize runs for strikes (normal and CMs/CM trainings) as well in the
                                upcoming months.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Fractals</h3>
                                Our in-game guild [UAoT] has several players who do the daily fractals and spontaneously
                                ask if someone wants to join. Other than that, we also have trainers and partner
                                communities who offer free-for-all training for all levels of fractals from Tier 1 to
                                CM.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={pve4} borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Open World</h3>
                                Our in-game guild [UAoT] has several players who do the daily fractals and spontaneously
                                ask if someone wants to join. Other than that, we also have trainers and partner
                                communities who offer free-for-all training for all levels of fractals from Tier 1 to
                                CM.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
            <RowGray>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>We are looking for commanders and trainers!</h3>
                                If you are interested in applying as a PVE guild, community or commander, please fill
                                out this form (to.tyria.art/apply) and we will get in touch with you in due time.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
        </Container>
    )
}