import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import art1 from "../../media/art/art1.jpg";
import {Container, Image} from "react-bootstrap";
import artTitleImage from "../../media/titles/art.png";
import RowGray from "../../component/Rows/RowGray/RowGray";

export default function NotFound () {

    return (
        <Container fluid style={{padding: 0, paddingTop: 10}}>
            <RowBackground title img={art1} borderBottom>
                <Image src={artTitleImage} className={"title-image"}/>
            </RowBackground>
            <RowGray>
                Oopsie, URL not found
            </RowGray>
        </Container>
    )
}