import "./Beetle.css";
import "./../../App.css";
import {Col, Container, Image, Row} from "react-bootstrap";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import beetle1 from "../../media/beetle/beetle1.jpg";
import beetle2 from "../../media/beetle/beetle2.jpg";
import beetle3 from "../../media/beetle/beetle3.jpg";
import beetleTitle from "../../media/titles/beetle.png";

export default function Beetle() {
    return (
        <Container fluid style={{padding: 0}}>
            <RowBackground title img={beetle1} borderBottom>
                <h1>BEETLE RACING</h1>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Another mount enters the stage</h3>
                                The Roller Beetle was introduced in Living Word Season 4. Its unique techniques and
                                ability to drift make it stand out from the other mounts. Being the fastest mount on
                                land, and even surpassing its intended? speed limit with certain drift-techniques, this
                                mount is nothing short of being made for racing tracks with it.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={beetle2} borderBottom>
                <Container>
                    <Row>
                        <Col md={12}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>The origins of beetle racing</h3>
                                Beetle racing began soon after the release of the mount half-way through 2018. What
                                started as humble squads, with improvised events that were held out in the open
                                world formed into its own niche long before the time trials and the associated
                                events were later added to the game by the end of the year. The addition of the race
                                adventures gave a new impulse to the community, as racers began competing for better
                                times on the leaderboards.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Custom race tracks</h3>
                                The newly added racetrack decorations paved the way for guild hall races on a whole
                                different level, setting the stage for guild hall based racing tournaments with massive
                                custom built race tracks. Between 2019 and 2020, these guild hall tournaments were held
                                continuously, leading up to the biggest tournament ever in the beetle racing world
                                championships in early 2021. We thought we had reached the pinnacle of beetle racing,
                                with tight optimizations that relied mostly on maintaining speed and optimally timing
                                your boosts. Little did we know that the best was yet to come.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={beetle3} borderBottom>
                <Container>
                    <Row>
                        <Col md={12}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Advanced techniques</h3>
                                In the weeks leading up to the world cup, two crucial developments took place that would
                                change beetle racing forever. First, the release of the speedometer tool made
                                by <a href={"https://www.beetlerank.com/speedometer"}>killer</a>, and the discovery of a
                                new drift acceleration technique: Air Cancelled Sliding
                                (ACS drift). ACS allows you to break the speed limit and gain speed while drifting and
                                jumping, without having to rely on boosting, which completely changes how you approach
                                beetle racing. Where previously you could only gain speed by boosting, now every drift
                                is an opportunity to accelerate and go faster than your rival. <br/>
                                After the world cup the community saw an explosive growth in members and activity, with
                                more and more racers trying and succeeding in mastering the newfound advanced methods.
                                The website https://www.beetlerank.com was created, which serves as a custom leaderboard
                                where time trials are uploaded directly from the speedometer tool. New discoveries were
                                made in the form of the hyperboost, a superpowered boosting technique where you drift
                                and boost at the same time, and the drift hold, which lets you maintain the overspeed
                                for long times. Even now there remains things that might’ve gone unnoticed, waiting for
                                someone to find them.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
        </Container>
    )
}