import "./CalenderIcon.css"

interface CalenderIconProps {
    date: Date;
    size: string;
}

export default function CalenderIcon(props: CalenderIconProps) {

    function dateToShortMonth(date: Date): string {
        return date.toLocaleString('en-us', { month: 'short' });
    }

    function size(): string {
        switch(props.size) {
            case "l": return "l"
            case "m": return "m"
            case "s": return "s"
            default: return "m"
        }
    }

    return (
        <div className={`calendar-icon-${size()}`}>
            <div className={`header-${size()}`}>
                {dateToShortMonth(new Date(props.date))}
            </div>
            <div className={`main-${size()}`}>
                {new Date(props.date).getDate()}
            </div>
        </div>
    )
}