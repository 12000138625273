import { grpc} from '@improbable-eng/grpc-web';

import {ApiServiceClient, ServiceError} from "uaot-api/grpc/uaot/api/v1/rpc_pb_service";

type CallbackType<Res> = (error: ServiceError, response: Res | null) => void;
type RequestType<Req, Res> = (
    (
        request: Req,
        metaData: grpc.Metadata,
        callback: CallbackType<Res>,
    ) => void
    );

export interface ApiRequest {
    client: ApiServiceClient;
    func: RequestType<any, any>;
    request: any;
    metadata?: grpc.Metadata;
}

function doApiRequest(r: ApiRequest): Promise<any> {
    let resolve: any;
    let reject: any;
    const promise: Promise<any> = new Promise<any>((res, rej) => {
        resolve = res;
        reject = rej;
    });

    const request = r;
    if (request.metadata === undefined) {
        request.metadata = new grpc.Metadata();
    }

    // We're losing a tiny bit of type safety here due to .call, i.e. the method
    // signature isn't type checked.
    request.func.call(
        request.client,
        request.request,
        request.metadata,
        ((error: ServiceError, response: any | null) => {
            if (response === null) {
                reject(error);
            } else {
                resolve(response);
            }
        }),
    );

    return promise;
}

const backendUrl = 'https://nevac-api.tyria.dev/uaot'
const apiServiceClient = new ApiServiceClient(backendUrl);


export {backendUrl, apiServiceClient, doApiRequest}