import "./Events.css";
import "./../../App.css";
import {Col, Container, Row} from "react-bootstrap";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import jp1 from "../../media/jumping-puzzle/jp1.jpg";
import {EventCardList} from "../../component/EventCard/EventCard";
import {EventList} from "../../component/EventList/EventList";
import {getEvents} from "../../api/event";
import {useEffect, useState} from "react";
import {Event} from "uaot-api/grpc/uaot/api/v1/event_pb";

export default function Events() {
    const [events, setEvents] = useState<Event[]>([])

    useEffect(() => {
        getEvents("%", 1, false).then(e => setEvents(e))
    }, []);

    return (
        <Container fluid style={{padding: 0}}>
            <RowBackground title img={jp1} borderBottom>
                <Row className={"justify-content-center"}>
                    <Container>
                        <Col md={12} style={{padding: "5% 0",}}>
                            <h1 className="header-page">EVENTS</h1>
                        </Col>
                    </Container>
                </Row>
            </RowBackground>
            <RowGray>
                <Container>
                    <EventCardList events={events.slice(0, 3)}/>
                    <div style={{marginBottom: 50}}/>
                    <EventList events={events.slice(3)}/>
                </Container>
            </RowGray>
        </Container>
)}
