import "./Landing.css";
import "./../../App.css";
import {Col, Container, Image, Row} from "react-bootstrap";
import ElementSlide from "../../component/ElementSlide/ElementSlide";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import Testimonial from "../../component/Testimonial/Testimonial";
import landing2 from "../../media/landing/landing2.png"
import landing3 from "../../media/landing/landing3.png"
import testiomanialAvatar1 from "../../media/landing/testamonialAvatar1.jpg";
import testiomanialAvatar2 from "../../media/landing/testamonialAvatar2.jpg";
import testiomanialAvatar3 from "../../media/landing/testamonialAvatar3.png";
import testiomanialAvatar4 from "../../media/landing/testamonialAvatar4.png";
import artLandingImg from "../../media/landing/art.png";
import beetleLandingImg from "../../media/landing/beetle.png";
import fashionLandingImg from "../../media/landing/fashion.jpg";
import griffonLandingImg from "../../media/landing/griffon.jpg";
import jumpingLandingImg from "../../media/landing/jumping.jpg";
import musicLandingImg from "../../media/landing/music.png";
import landingTitle from "../../media/titles/mainpage.png";
import Timeline from "../../component/Timeline/timeline";
import RowLightGray from "../../component/Rows/RowLightGray/RowLightGray";

const events = [
    {
        title: "GRIFFON FLYING",
        imageUrl: griffonLandingImg,
        text: "The community has discovered a good while ago that the Griffon is incredibly fun and started maneuvering this jet through tight caves, low bridges or any kind of obstacle that bears a challenge.",
        buttonText: '',
        date: new Date(),
        onClick: () => {},
    },
    {
        title: "BEETLE RACING",
        imageUrl: beetleLandingImg,
        text: "Being all about speed, technique and control, the Roller Beetle is the fastest mount in the game. Discover everything this community has to offer: From special drift methods to a community made speedometer tool with custom racetracks!",
        buttonText: '',
        date: new Date(),
        onClick: () => {},
    },
    {
        title: "FASHION WARS",
        imageUrl: fashionLandingImg,
        text: "The true endgame as we all know it! We love showing off and sharing our character creations and newest styles, be it on the Discord or in a Fashion Contest!",
        buttonText: '',
        date: new Date(),
        onClick: () => {},
    },
    {
        title: "JUMPING PUZZLES",
        imageUrl: jumpingLandingImg,
        text: "Alongside the official jumping puzzles, the community embraces the possibilities of creating their own versions for fans in custom made guild hall JPs in which you can relive the feeling of exploring fresh jumping puzzles once again.",
        buttonText: '',
        date: new Date(),
        onClick: () => {},
    },
    {
        title: "IN-GAME MUSIC",
        imageUrl: musicLandingImg,
        text: "Guild Wars 2 offers a variety of instruments a player can equip and play. Certain communities are dedicated to transpose songs into a playable in-game version and come together to solo, duet, trio, quartett or even as a whole squad full of people to play arrangements.",
        buttonText: '',
        date: new Date(),
        onClick: () => {},
    },
    {
        title: "ART",
        imageUrl: artLandingImg,
        text: "Our community is known for our affection to art, be it digital or traditional. We support artists in whatever area they need help with and encourage showcasing and discussing WIPs, sketches and finished works.\n",
        buttonText: '',
        date: new Date(),
        onClick: () => {},
    },
];

export default function Landing() {

    const testimonials = [
        <Testimonial
            quote={"Undoubtedly the most friendly, supportive and loving community I’ve ever been with! The variety of artists is phenomenal and UAoT assists artists of all ranges with fun events and much more!"}
            quoteBy={"HungryDima, Creative Crew"} avatar={testiomanialAvatar1}/>,
        <Testimonial
            quote={"Fashion, mount racing, jumping puzzles; you'll find all kinds of fun side activities here, garnished with a well mannered community. The journey with UAoT starts where the endgame ends and I am more than happy to be a part of it!"}
            quoteBy={"Toxxa/Fenda, Community Member"} avatar={testiomanialAvatar2}/>,
        <Testimonial
            quote={"UAoT is nothing like any other guild I have seen. I have never been met with so many including and positive people anywhere else. Every day I am excited to log in and get to hang with everyone here. The community and all the events are just awesome, you always feel like you can be yourself here. No matter what your life is like and what experience you have from GW2 you will always feel a warm and kind welcome from all the members in UAoT."}
            quoteBy={"Kazro, Commander"} avatar={testiomanialAvatar3}/>,
        <Testimonial
            quote={"I recently found this community a few months ago and I immediately found myself falling in love with this group of players. Everyone is very friendly & helpful, I could not ask for a more professional and caring alliance. I've felt looked after the moment I arrived. Everyone is the best of themselves & strives to entertain, look after"}
            quoteBy={"Danny/Aiyosi, Community Member"} avatar={testiomanialAvatar4}/>
    ];

    return (
        <Container fluid style={{padding: 0}}>
            <RowBackground title img={landing2} borderBottom position={"top"}>
                <div className={"title-spacing"}>
                    <h1>DISCOVER THE ARTS OF TYRIA</h1>
                    <h2 style={{textShadow: '5px 5px 3px rgba(0, 0, 0, .3)'}}>GUILD WARS' MOST VERSATILE COMMUNITY</h2>
                </div>
            </RowBackground>
            <RowLightGray borderBottom>
                <Row style={{margin: 0}}>
                    <Col md={5} className={"responsive-margin"}>
                        <Row className={"justify-content-center"}>
                            <Col>
                                <h4>WHAT IS UNITED ARTS OF TYRIA?</h4>
                            </Col>
                        </Row>
                        <Row className={"justify-content-center"}>
                            <Col>
                                <h3>~</h3>
                            </Col>
                        </Row>
                        <Row className={"justify-content-center"}>
                            <Col style={{textAlign: "center", textAlignLast: "center"}}>
                                United Arts of Tyria [UAoT] is a guild-alliance for alternative forms of endgame, where
                                you can come together for an exchange of knowledge and resources. We are about creating
                                an opportunity for communities that have a unique concept and show it to the general
                                playerbase.
                            </Col>
                        </Row>
                    </Col>
                    <Col md={1} className={"division-line"}></Col>
                    <Col md={1}></Col>
                    <Col md={5}>
                        <Row className={"justify-content-center"}>
                            <Col>
                                <h4>WHAT MAKES US SPECIAL?</h4>
                            </Col>
                        </Row>
                        <Row className={"justify-content-center"}>
                            <Col>
                                <h3>~</h3>
                            </Col>
                        </Row>
                        <Row className={"justify-content-center"}>
                            <Col style={{textAlign: "center", textAlignLast: "center"}}>
                                We create custom-made content that is not provided as an endgame, like contests/events
                                and activities either by ourselves or with the help of our partner-guilds. If
                                streamers/content creators are interested, we are open to collaborate to showcase our
                                events through their stream for example. We aspire for a community based on inclusivity,
                                free of toxicity.
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </RowLightGray>
            <RowGray>
                <Row>
                    <Col>
                        <h1 style={{marginBottom: "2em"}}>CATEGORIES</h1>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <Timeline events={events} denseLayout={true}/>
                    </Col>
                </Row>
            </RowGray>
            <RowBackground img={landing3} position={"top"}>
                <ElementSlide elements={testimonials} height={"300px"} interval={10000}/>
            </RowBackground>
        </Container>
    )
}