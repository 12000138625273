import './ImageGrid.css';
import ImageC from "../Image/ImageC";
import Masonry from "react-masonry-css";

interface ImageGridProps {
    images: string[];
}

export default function ImageGrid(props: ImageGridProps) {

    const breakpointColumnsObj = {
        default: 4,
        1100: 3,
        700: 2,
        500: 1
    };

    function renderRows() {
        let renderedImages: any = [];

        props.images.forEach((value, index) => {
            renderedImages.push(
                <ImageC fluid modal src={value} style={{marginBottom: 20, borderRadius: 10}}/>
            )
        })

        return renderedImages;
    }

    return (
        <Masonry
            breakpointCols={breakpointColumnsObj}
            className={"my-masonry-grid"}
            columnClassName={"my-masonry-grid_column"}>
            {renderRows()}
        </Masonry>
    )
}