import "./Fashion.css";
import "./../../App.css";
import {Col, Container, Image, Row} from "react-bootstrap";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import fashion1 from "../../media/fashion/fashion1.jpg";
import fashion2 from "../../media/fashion/fashion2.png";
import fashionTitle from "../../media/titles/fashion.png";

export default function Fashion() {
    return (
        <Container fluid style={{padding: 0}}>
            <RowBackground title img={fashion1} borderBottom>
                <h1>FASHION WARS</h1>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Who’s the fairest of them all?</h3>
                                It’s many hours you get to look at your own character models in the game. For this very
                                reason, many people want theirs to look spot on. In United Arts of Tyria, many people
                                are dedicated to this aspect of the game that people don’t call “Fashion Wars” for
                                nothing. Hundreds of gold for a dye? Thousands of gold for an infusion, just for
                                aesthetics? Some of our members are certainly no strangers to that.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={fashion2} borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Fashion Contests</h3>
                                We regularly host fashion contests for our communities. Mostly these feature a certain
                                theme, be it bound by a seasonal event or a fun new idea we make up.
                                On top of that we also do fun dress codes for events with our fashionistas to make it a
                                more fun experience. <br/>
                                What makes our contests special is that most of them are judged by very experienced,
                                veteran players that can name you almost every armor piece in this game and how to
                                obtain it. We invented a method where a contestant is judged based on categories like
                                theme, creativity, dyes, and bonus category/categories. <br/>
                                Making sure that even the contestants that are waiting in line at our fashion contests
                                are constantly entertained, we have very talented music players from guilds that play
                                in-game concerts/performances for players and themselves.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
        </Container>
    )
}