import "./Footer.css";
import {Col, Container, Row} from "react-bootstrap";
import logo from "../../media/logoNoText.png";
import discordLogo from "../../media/discord.svg";
import instagramLogo from "../../media/instagram.svg";
import twitchLogo from "../../media/twitch.svg";
import twitterLogo from "../../media/twitter.svg";
import ButtonIcon from "../ButtonIcon/ButtonIcon";
import {Contact} from "../Contact/Contact";

export default function Footer(props) {

    function openInNewTab(url) {
        var win = window.open(url, '_blank');
        win.focus();
    }

    return (
        <div id={"footer"}>
            <Container>
                <Row>
                    <Col xs={"auto"} style={{textAlign: "left"}}>
                        <img
                            src={logo}
                            height="70"
                            className="d-inline-block align-top"
                            alt="React Bootstrap logo"
                        />
                    </Col>
                    <Col md={8}>
                        <Row>
                            <Col style={{textAlign: "left"}} md={"4"}>
                                <Contact name={"Deathblade"} discord={"Deathblade#9717"} gw2Account={"Deathblade.4856"}/>
                            </Col>
                            <Col style={{textAlign: "left"}} md={"4"}>
                                <Contact name={"Nyoko"} discord={"Nyoko#7777"} gw2Account={"semf.5967"}/>
                            </Col>
                            <Col style={{textAlign: "left"}} md={"4"}>
                                <Contact name={"Nemain"} discord={"Nemain#1313"} gw2Account={"Tactical.3029"}/>
                            </Col>
                            <Col style={{textAlign: "left"}} md={"4"}>
                                <Contact name={"Reaper"} discord={"Rippity Reaper#1920"} gw2Account={"Reaper.3871"}/>
                            </Col>
                            <Col style={{textAlign: "left"}} md={"4"}>
                                <Contact name={"Rei"} discord={"Reirakiyo#7058"} gw2Account={"Reirakiyo.3591"}/>
                            </Col>
                            <Col style={{textAlign: "left"}} md={"4"}>
                            </Col>
                        </Row>
                        <Row>

                        </Row>
                    </Col>
                    <Col style={{justifyContent: "right", display: "flex"}}>
                        <ButtonIcon icon={discordLogo} size={20} onClick={() =>
                            openInNewTab("https://to.tyria.art/discord")}/>
                        <ButtonIcon icon={instagramLogo} size={20} onClick={() =>
                            openInNewTab("https://www.instagram.com/unitedartsoftyria.gw2/")}/>
                        <ButtonIcon icon={twitterLogo} size={20} onClick={() =>
                            openInNewTab("https://twitter.com/UAoT_GW2")}/>
                        <ButtonIcon icon={twitchLogo} size={20} onClick={() =>
                            openInNewTab("https://www.twitch.tv/unitedartsoftyria")}/>
                    </Col>
                </Row>
            </Container>
        </div>
    );
}