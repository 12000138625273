import './ImageModal.css';
import {Image, Modal} from "react-bootstrap";

interface ImageSlideProps {
    show: boolean;
    onHide: () => void;
    image: string;
}

export default function ImageModal(props: ImageSlideProps) {
    return (
        <Modal
            style={{backgroundColor: "none"}}
            {...props}
            size="xl"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Image fluid src={props.image}/>
        </Modal>
    )
}