import deathbladeImg from '../../media/about-us/kenny.png';
import nyokoImg from '../../media/about-us/nyoko.png';
import nemainImg from '../../media/about-us/nem.png';
import reaperImg from '../../media/about-us/reaper.png';
import reiImg from '../../media/about-us/rei.png';

export class TeamMember {
    name: string;
    image: string;
    tasks: string[];
    specialTasks: string[];
    background: string;
    interests: string;

    constructor(name: string, image: string, tasks: string[], specialTasks: string[], background: string, interests: string) {
        this.name = name;
        this.image = image;
        this.tasks = tasks;
        this.specialTasks = specialTasks;
        this.background = background;
        this.interests = interests;
    }
}

export const teamMembers: Map<string, TeamMember> = new Map<string, TeamMember>();

const deathblade = new TeamMember(
    'Deathblade',
    deathbladeImg,
    ['Founder', 'Fashion King', 'Networ-King (Pun-King)', 'Passionate and enthusiastic', 'Loves to eat',
        'Enjoys talking and bringing people together', 'multitasking'],
    ['External communication / networking', 'Recruiting members, team-members and partners',
        'Kicks off spontaneous activities/contest ideas'],
    'When I originally started Styled to Death [STD] the fashion community back then, I decided to reach out to other artistic communities to combine them as one and form The United Arts of Tyria [UAoT] to create a diverse community about multiple fun events/activities/contests you name it.',
    'I like to mostly do fashion, You can find my portfolio here https://gw2style.com/profile.php?id=10025 if you are interested to see my creations or would like to see my service about it. I love to participate in fashion contests and bring something unique to the table. After a while I decided to run my own fashion contests and plan/negotiate with the team to create something fun for everybody to enjoy or simply try to improve our community together. In and outside of the game i love to expand networks and bring people together. Love to decorate guild halls. My passions are fashion/fitness/art & gaming.'
);
teamMembers.set('deathblade', deathblade);

const nyoko = new TeamMember(
    'Nyoko',
    nyokoImg,
    ['Creative and innovative', 'Spreadsheet/document warrior', 'Organization talent', 'Eye for detail', 'Lead of [JP], you know, the jumping freaks',
        'Can only do 1 thing at a time', 'Can only do 1 thing at a time'],
    ['Internal organization & documentation', 'UAoT design scheme, team-members and partners',
        'Spreadsheets for live events, stream overlays and organization', 'Website team (Alongside Nevac, Nuriel and Nemain)'],
'During the formation of UAoT, I was made aware of the alliance idea by @NurielNightshade [JP] who got to know about it in the Overflow mod team and he asked me as lead of the [JP] guild as representative for the jumping puzzle activities. I joined the council when we decided to have a leadership team of 5 and stayed till now.',
    'Played GW since factions - hence the name nyoko, as i thought that\'s a good name for canthan character. Did the mainstream PVE content including fractals and raid CMs (maybe 1 or 2 missing). Passionate PvP and farming-hater. Known for only playing my main mesmer for everything. Why? Due to lack of "intended" activities I would enjoy which require many classes, we instead had tons of fun porting, skipping and guiding people through all the JPs in the game and found our chillout space in Not So Secret and Chalice of Tears JPs - this is where and why we formed the [JP] guild. Collecting guild hall JPs and offer to visit them to everyone. I also enjoy Beetle Racing and Griffoning, but not nearly as good as the pros around here... Outside the game I think I studied biochemistry/-technology and create NEW LIFE FROM SCRATCH in pharma industry with plans to take over the world.'
    );
teamMembers.set('nyoko', nyoko);

const nemain = new TeamMember(
    'Nemain',
    nemainImg,
    ['Social Media nerd', 'Loves image and video editing', 'Loves griffon flying, mod in [Wing]', 'Rarely has a bad day', 'Professional napper',
        'Gaht steil ab HORNS'],
    ['Responsible for most announcement graphics', 'UAoT design scheme',
        'Social media responsible (Insta, Twitter, Twitch, YT)', 'Advertisement media', 'Website team (Alongside Nevac, Nuriel and Nyoko)'],
    'I joined the Griffon guild [Sky] in the very early days of the guild and later got invited by Kenny to join the fashion guild [STD] while hanging around in Divinity\'s Reach. My first UAoT event I helped out with was the Djinn JP in the UAoT guildhall. After that I assisted the guild more frequently, became a deputy for STD as Associate and later joined the Council.',
    'I am an avid Griffonflier and enjoy the harder content of the game like Raids and Strike CMs. Fashion wars is one of my passions and I love taking screenshots or videos of my characters and editing them. Outside of the game I\'m a Social Media nerd, community manager, video and image editor, white Monster Energy addict, an expert in taking naps and I enjoy Metal music and the company of my best friend, my Maine Coon cat.'
);
teamMembers.set('nemain', nemain);

const reaper = new TeamMember(
    'Reaper',
    reaperImg,
    ['Beetle racer from before it was cool', 'Admin in [RACE]', 'Green', 'Occasionally modest', 'Imperturbable',
        'Part time philosopher'],
    ['Main link to the beetle community', 'Consultant in difficult situations', 'Content contributor',
        'Organization and rules for beetle events'],
'I joined the alliance together with [RACE] as an associate during the early days of the alliance\'s formation, became a deputy and a spokesperson of the beetle community for the council and eventually succeeded Garfried as an official council member.',
'I like to roll around on my trusty Roller Beetle for a couple of years now and apparently i\'m pretty decent at it or so I\'m told. The joy of movement is my main attraction with GW2, and so I enjoy all movement related activities such as time trials, race events, stunt flying and jumping puzzle parkour. I\'ve also developed a newfound interest in fishing and speedboating. Outside of the game I ponder life and am often joined in these deep thoughts by others which usually escalates into lengthy spiritual conversations. I\'m searching for my place in this world, which I\'m sure is a sentiment a lot of people can relate to, but I\'d wager I\'m making good progress.'
);
teamMembers.set('reaper', reaper);

const rei = new TeamMember(
    'Rei',
    reiImg,
    ['Representative of the Beetle & Griffon community', 'Admin in [RACE]', 'Pro griffon cam in Beetle races', 'freaking amazing artist',
        'Loves taking screenshots'],
    ['Main Artist representative', 'Link to griffon community', 'Organization and rules for griffon- and art events'],
    'I joined around 2 years ago and I believe I joined through either Sky or STD back then.',
    'Within the game I like to try out everything at least once and even though my main interest was the mounts for the longest time, nowadays I always love to do other stuff like pve, achievement hunting etc. Outside the game I mostly just do art atm, still want to get back to doing cosplays and picking up a sport again some time.'
    );
teamMembers.set('rei', rei);