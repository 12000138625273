import './ImageSlide.css';
import {Carousel, } from "react-bootstrap";
import ImageModal from "../ImageModal/ImageModal";
import {useState} from "react";

interface ImageSlideProps {
    images: string[];
}

export default function ImageSlide(props: ImageSlideProps) {

    const [modalData, setModalData] = useState({
        show: false,
        image: ""
    });

    function generateCarouselItem(image: string) {
        return (
            <Carousel.Item key={image}>
                <img
                    className="d-block w-100"
                    src={image}
                    alt="First slide"
                    onClick={() => setModalData({show: true, image: image})}
                    style={{cursor: "pointer"}}/>
            </Carousel.Item>
        )
    }

    return (
        <>
            <Carousel>
                {props.images.map(image => generateCarouselItem(image))}
            </Carousel>
            <ImageModal show={modalData.show}
                        onHide={() => setModalData({show: false, image: modalData.image})}
                        image={modalData.image}/>
        </>
    )
}