// package: uaot.api.v1
// file: uaot/api/v1/rpc.proto

var uaot_api_v1_rpc_pb = require("../../../uaot/api/v1/rpc_pb");
var uaot_api_v1_requests_pb = require("../../../uaot/api/v1/requests_pb");
var uaot_api_v1_responses_pb = require("../../../uaot/api/v1/responses_pb");
var grpc = require("@improbable-eng/grpc-web").grpc;

var ApiService = (function () {
  function ApiService() {}
  ApiService.serviceName = "uaot.api.v1.ApiService";
  return ApiService;
}());

ApiService.GetEvents = {
  methodName: "GetEvents",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: uaot_api_v1_requests_pb.GetEventRequest,
  responseType: uaot_api_v1_responses_pb.GetEventResponse
};

ApiService.ListEvents = {
  methodName: "ListEvents",
  service: ApiService,
  requestStream: false,
  responseStream: false,
  requestType: uaot_api_v1_requests_pb.ListEventRequest,
  responseType: uaot_api_v1_responses_pb.ListEventResponse
};

exports.ApiService = ApiService;

function ApiServiceClient(serviceHost, options) {
  this.serviceHost = serviceHost;
  this.options = options || {};
}

ApiServiceClient.prototype.getEvents = function getEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.GetEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

ApiServiceClient.prototype.listEvents = function listEvents(requestMessage, metadata, callback) {
  if (arguments.length === 2) {
    callback = arguments[1];
  }
  var client = grpc.unary(ApiService.ListEvents, {
    request: requestMessage,
    host: this.serviceHost,
    metadata: metadata,
    transport: this.options.transport,
    debug: this.options.debug,
    onEnd: function (response) {
      if (callback) {
        if (response.status !== grpc.Code.OK) {
          var err = new Error(response.statusMessage);
          err.code = response.status;
          err.metadata = response.trailers;
          callback(err, null);
        } else {
          callback(null, response.message);
        }
      }
    }
  });
  return {
    cancel: function () {
      callback = null;
      client.close();
    }
  };
};

exports.ApiServiceClient = ApiServiceClient;