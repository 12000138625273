import styles from './ButtonIcon.module.css';
import {Button} from "react-bootstrap";

interface ButtonIconProps {
    icon: string;
    size: number;
    onClick: (...input: any) => void
}

export default function ButtonIcon(props: ButtonIconProps) {
    return (
        <Button className={`${styles.customBtn} shadow-none`} onClick={props.onClick}
                style={{width: props.size*2, height: props.size*2}}>
            <img
                src={props.icon}
                width={props.size}
                height={props.size}
                className={`d-inline-block align-top ${styles.icon}`}
                alt={'button'}
            />
        </Button>
    )
}