import './TeamMemberDetails.css'
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import art1 from "../../media/art/art1.jpg";
import {Col, Container, Image, Row} from "react-bootstrap";
import artTitleImage from "../../media/titles/art.png";
import RowGray from "../../component/Rows/RowGray/RowGray";
import {useHistory, useParams} from "react-router-dom";
import {TeamMember, teamMembers} from "./TeamMemberModel";
import H from 'history';

export default function TeamMemberDetails () {
    type TeamMemberDetailsParams = {
        id: string;
    }

    let {id} = useParams<TeamMemberDetailsParams>();
    let history = useHistory<H.LocationState>();

    const teamMember: TeamMember | undefined = teamMembers.get(id);
    if(!teamMember) {
        history.push('/not-found');
    }

    return (
        <Container fluid style={{padding: 0, paddingTop: 10}}>
            <RowBackground title img={art1} borderBottom>
                <Image src={artTitleImage} className={"title-image"}/>
            </RowBackground>
            <RowGray>
                <Row>
                    <Col md={"auto"} style={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        <img alt='avatar' className={'team-member-details-image'} src={teamMember?.image}/>
                    </Col>
                    <Col style={{textAlign: 'left'}}>
                        <Row>
                            <Col className={'team-member-details-col'}>
                                <div className={'team-member-details-box'}>
                                    <h3 className={'team-member-details-title'}>{teamMember!.name}</h3>
                                </div>
                            </Col>
                        </Row>
                        <Row>
                            <Col md={6} className={'team-member-details-col'}>
                                <div className={'team-member-details-box'}>
                                    <h5>Tasks</h5>
                                    <hr/>
                                    <div style={{fontSize: 14}}>
                                        {teamMember?.tasks.map(t => <p>- {t}</p>)}
                                    </div>
                                </div>
                            </Col>
                            <Col md={6} className={'team-member-details-col'}>
                                <div className={'team-member-details-box'}>
                                    <h5>Special Tasks</h5>
                                    <hr/>
                                    <div style={{fontSize: 14}}>
                                        {teamMember?.specialTasks.map(t => <p>- {t}</p>)}
                                    </div>
                                </div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row>
                    <Col className={'team-member-details-col'}>
                        <div className={'team-member-details-box'}>
                            <h5>How did you learn about/join UAoT/the alliance; When did you join the team?</h5>
                            <hr/>
                            {teamMember?.background}
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col className={'team-member-details-col'}>
                        <div className={'team-member-details-box'}>
                            <h5>How did you learn about/join UAoT/the alliance; When did you join the team?</h5>
                            <hr/>
                            {teamMember?.interests}
                        </div>
                    </Col>
                </Row>
            </RowGray>
        </Container>
    )
}