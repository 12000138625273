import "./Art.css";
import "./../../App.css";
import {Col, Container, Image, Row} from "react-bootstrap";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import art1 from "../../media/art/art1.jpg";
import art2 from "../../media/art/art2.png";
import art3 from "../../media/art/art3.png";
import ImageC from "../../component/Image/ImageC";
import ImageGrid from "../../component/ImageGrid/ImageGrid";

import artTitleImage from "../../media/titles/art.png";
import gridImage1 from "../../media/art/gridImage1.png"
import gridImage2 from "../../media/art/gridImage2.jpg"
import gridImage3 from "../../media/art/gridImage3.png"
import gridImage4 from "../../media/art/gridImage4.jpg"
import gridImage5 from "../../media/art/gridImage5.png"
import gridImage6 from "../../media/art/gridImage6.png"
import gridImage7 from "../../media/art/gridImage7.jpg"
import gridImage8 from "../../media/art/gridImage8.jpg"
import gridImage9 from "../../media/art/gridImage9.png"
import gridImage10 from "../../media/art/gridImage10.png"
import gridImage11 from "../../media/art/gridImage11.png"
import gridImage12 from "../../media/art/gridImage12.png"
import gridImage13 from "../../media/art/gridImage13.jpg"
import gridImage14 from "../../media/art/gridImage14.png"
import gridImage15 from "../../media/art/gridImage15.png"
import gridImage16 from "../../media/art/gridImage16.png"

export default function Art() {

    const imagesCreative = [gridImage1, gridImage2, gridImage3, gridImage4, gridImage5, gridImage6, gridImage7,
        gridImage8, gridImage9, gridImage10, gridImage11, gridImage12, gridImage13, gridImage14, gridImage15,
        gridImage16];

    return (
        <Container fluid style={{padding: 0}}>
            <RowBackground title img={art1} borderBottom>
                <h1>CREATIVE CORNER</h1>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                We offer a comfort zone for all artists to come together and express their passion for
                                art in one place. Our Discord is a platform where artists can share their amazing works
                                and portfolios. Their art can be streamed on the server or over on our Twitch channel if
                                desired. They can teach other artists new techniques and tips/tricks to help eachother
                                out and broaden their knowledge.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={art3} borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Commission system</h3>
                                Did you know our server features a ticket system which allows you to get in touch with
                                our artists in case you want to commission a piece? You can commission anything from
                                traditional drawings, screenshots/edits, logos, emojis to infographics.
                            </p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <ImageC fluid src={art2} modal/>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
            <RowGray>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Creative Crew</h3>
                                Our Creative Crew helps us by offering their creative services to the guild. They create
                                artworks for events and announcements or offer their art as a reward for contest
                                winner(s) or videographers can help us by creating short teasers for events.
                                This collective of artists get priority if a commission request gets opened on the
                                Discord server.
                                Skills and specializations can range from videographers to drawings/digital/edits/3D
                                arts etc. If you are interested in joining the Creative Crew, feel free to apply <a href={"to.tyria.art/apply"}>here</a>.
                            </p>
                        </Col>
                        <Col md={6} style={{margin: 0}}>

                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowGray>
                <ImageGrid images={imagesCreative}/>
            </RowGray>
        </Container>
    )
}