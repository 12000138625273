import "./Contact.css"
import {Container} from "react-bootstrap";
interface ContactProps {
    name: string;
    discord: string;
    gw2Account: string;
}

export function Contact(props: ContactProps) {

    return (
        <Container style={{marginBottom: 20, padding: 0}}>
            <div style={{display: "flex"}}>
                <div style={{textAlign: "right", marginRight: 10, width: 70}}>
                    <b>{props.name}</b>
                </div>
                <div style={{width: 120}}>
                    {props.discord}<br/>
                    {props.gw2Account}
                </div>
            </div>
        </Container>
    )
}