export class DateFormatter {

    //Example: 19:04
    static formatTimeHHMM(date: Date): string {
        let minutes: number = date.getMinutes();
        let hours: number = date.getHours();

        let minuteString: string = `${minutes < 10 ? 0 : ''}${minutes}`;
        let hourString: string = `${hours < 10 ? 0 : ''}${hours}`;

        return `${hourString}:${minuteString}`;
    }

    //Example: Tuesday 25. January
    static formatDateEdM(date: Date): string {
        return `${date.toLocaleDateString('en-us', {weekday: 'long'})} ${date.getDate()}.
                ${date.toLocaleString('en-us', { month: 'long' })}`
    }
}