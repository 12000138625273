import "./EventCard.css"
import {Button, Card, Col, Row} from "react-bootstrap";
import { useHistory } from "react-router-dom";
import CalenderIcon from "../CalenderIcon/CalenderIcon";
import {Event} from "../../../jslib/grpc/uaot/api/v1/event_pb";
import {DateFormatter} from "../../util/DateFormatter";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";

interface EventCardListProps {
    events: Event[];
}

export function EventCardList(props: EventCardListProps) {
    return (
        <Row>
            {props.events.map((e, i) =>
                <Col key={e.getId()}>
                    <EventCard event={e}/>
                </Col>
            )}
        </Row>
    )
}

interface  EventCardProps {
    event: Event
}

export function EventCard(props: EventCardProps) {
    let history = useHistory();

    function openEventDetail(id: string): void {
        history.push(`/events/${id}`);
    }

    function validateTimestamp(date?: Timestamp): Date {
        if(date) {
            return date.toDate();
        }
        return new Date();
    }

    return (
        <Card className="event-card" style={{width: '18rem', textAlign: "left"}}>
            <Card.Body>
                <CalenderIcon size={"l"} date={validateTimestamp(props.event.getStartingAt())}/>
                <div style={{marginBottom: 20}}/>
                <Card.Title>{props.event.getName()}</Card.Title>
                <Card.Subtitle className="mb-2 text-muted">
                    {DateFormatter.formatTimeHHMM(validateTimestamp(props.event.getStartingAt()))}
                </Card.Subtitle>
                <Card.Subtitle className="mb-2 text-muted">@ {props.event.getLocation()}</Card.Subtitle>
                <Button onClick={() => openEventDetail(props.event.getId())} variant="primary">Read more</Button>
            </Card.Body>
        </Card>
    )
}