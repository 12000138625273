import "./Griffon.css";
import "./../../App.css";
import {Col, Container, Image, Row} from "react-bootstrap";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import griffon1 from "../../media/gtiffon/griffon1.jpg";
import griffon2 from "../../media/gtiffon/griffon2.jpg"
import griffon3 from "../../media/gtiffon/griffon3.png"
import IFrame from "../../component/IFrame/IFrame";
import griffonTitle from "../../media/titles/griffon.png";

export default function Griffon() {

    const videoSlideElements = [
        <IFrame style={{border: "solid 3px #E5B178"}}
                src="https://www.youtube.com/embed/mnwuz3gPSUg"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>,
        <IFrame style={{border: "solid 3px #E5B178"}}
                src="https://www.youtube.com/embed/78DzI0rMzT8"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>,
        <IFrame style={{border: "solid 3px #E5B178"}}
                src="https://www.youtube.com/embed/gWtnvyASri8"
                title="YouTube video player" frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen/>
    ]

    function onVideoSlide() {
        videoSlideElements.forEach(e => console.log(e))
    }

    return (
        <Container fluid style={{padding: 0}}>
            {onVideoSlide()}
            <RowBackground title img={griffon1} borderBottom>
                <h1>GRIFFON FLYING</h1>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>The secret mount</h3>
                                The Griffon mount has been introduced with the Path of Fire expansion as a hidden bonus
                                mount. Since then, many players have found great joy in flying these noble and fast
                                creatures through every cave and obstacle in Tyria. This passion went so far that today,
                                there is a whole set of communities that focus on flying stunts, tricks and routes on
                                their griffons.<br/><br/>
                                They enjoy the game by soaring in the skies, where they have total freedom. They keep on
                                challenging themselves to get better each time they fly together. Our associated griffon
                                guilds organize stunt contests from time to time to find the most proficient and
                                talented fliers in Guild Wars 2.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={griffon2} borderBottom>
                <Container>
                    <Row>
                        <Col md={6} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <IFrame style={{border: "solid 3px #E5B178"}}
                                        src="https://www.youtube.com/embed/hBlqEdn12SI"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen/>
                            </p>
                        </Col>
                        <Col md={6} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Spreading the wings</h3>
                                The first ever griffon guild was founded on NA servers. Later, at the end of 2018, the
                                EU counterpart followed. In 2021, a second EU-community was created which today also
                                covers NA events. For example, workshops are organized to improve tight turns, reaction
                                times and muscle memory. Fliers often share their hardest challenges with the community
                                to encourage training and showing off the mount’s abilities. If they are not training,
                                they play games like Griffon-jousting or relay races. Griffon parades are a popular
                                activity as well, where a flock of Griffons and their riders rp-walk through major
                                cities and enjoy themselves.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={6} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Custom races</h3>
                                In 2021, custom racing tracks have gained traction in the community and more and more
                                are added as time goes by. Griffon racers fight for the top placements, made possible
                                with the speedometer tool, be it open world or guild hall tracks. An interesting
                                activity for advanced griffon fliers are beetle racing events as well, where they take
                                over the role of the “bird perspective” or top view like a drone. In livestreams, this
                                gives a whole new level of entertainment and showcases the skills a player needs to
                                control these mounts.
                            </p>
                        </Col>
                        <Col md={6} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <IFrame style={{border: "solid 3px #E5B178"}}
                                        src="https://www.youtube.com/embed/Qz_ovnLqHeg"
                                        title="YouTube video player" frameBorder="0"
                                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                        allowFullScreen/>
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={griffon3} borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Open world challenges</h3>
                                Fliers also challenge themselves and each other to complete open world events on their
                                Griffon, like collecting Casino Coins, the Firecracker race at Lunar New Year or even
                                the open world Beete races.
                            </p>
                        </Col>
                    </Row>
                    <Row style={{justifyContent: "center"}}>
                        <Col md={8}>
                            {/*<VideoSlide videos={videos} height={"100%"}/>*/}
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
        </Container>
    )
}