import "./EventDetails.css";
import RowGray from "../../component/Rows/RowGray/RowGray";
import {useEffect, useState} from "react";
import {Col, Container, Row} from "react-bootstrap";
import jp1 from "../../media/jumping-puzzle/jp1.jpg";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import {useParams} from "react-router-dom";
import {getEvent} from "../../api/event";
import {Event} from "uaot-api/grpc/uaot/api/v1/event_pb";
import {DateFormatter} from "../../util/DateFormatter";
import {Timestamp} from "google-protobuf/google/protobuf/timestamp_pb";

export default function EventDetails() {

    interface PathParams {
        id: string;
    }

    const [event, setEvent] = useState<Event>()
    let { id } = useParams<PathParams>();

    useEffect(() => {
        getEvent(id).then(e => {console.log(e); setEvent(e)});
    }, []);

    function validateTimestamp(date?: Timestamp): Date {
        if(date) {
            return date.toDate();
        }
        return new Date();
    }

    return (
        <Container fluid style={{padding: 0}}>
            <RowBackground title img={jp1} borderBottom>
                {event ?
                    <Col md={12} style={{padding: "5% 0", textAlign: "left"}}>
                        <h1 className="header-page">{event.getName()}</h1>
                        <h5>
                            {`${DateFormatter.formatDateEdM(validateTimestamp(event.getStartingAt()))}, 
                            ${DateFormatter.formatTimeHHMM(validateTimestamp(event.getStartingAt()))}`}
                        </h5>
                        <h5>{event.getLocation()}</h5>
                    </Col>
                    : <></>
                }
            </RowBackground>
            <RowGray>
                <Row style={{margin: 0, textAlign: "left"}}>
                    <Col xs={6}>
                        <Row className={"row-margin"}>
                            <h4>NAME OF THE EVENT</h4>
                            <p className={"event-name"}>{event ? event.getName() : ''}</p>
                        </Row>
                        <Row className={"row-margin"}>
                            <h4>ABOUT THE EVENT</h4>
                            <p>{event ? event.getDescription() : ''}</p>
                        </Row>
                        <Row className={"row-margin"}>
                            <h4>RULES</h4>
                            <p>{event ? event.getDescription() : ''}</p>
                        </Row>
                        <Row className={"row-margin"}>
                            <h4>LOCATION</h4>
                            <p>{event ? event.getLocation() : ''}</p>
                        </Row>
                        <Row className={"row-margin"}>
                            <h4>DATE AND TIME</h4>
                            <p>
                                {event ?
                                    `${DateFormatter.formatDateEdM(validateTimestamp(event.getStartingAt()))},
                                    ${DateFormatter.formatTimeHHMM(validateTimestamp(event.getStartingAt()))}`
                                    : ''}
                            </p>
                        </Row>
                        <Row className={"row-margin"}>
                            <h4>PRIZE POOL</h4>
                            <p>{event ? event.getDescription() : ''}</p>
                        </Row>
                    </Col>
                    <Col xs={6}>
                        <Row className={"row-margin"}>
                            <h4>TAGS</h4>
                            <p>{event ? event.getDescription() : ''}</p>
                        </Row>
                        <Row className={"row-margin"}>
                            <h4>WATCH THE LIVE EVENT HERE</h4>
                            <p>{event ? event.getDescription() : ''}</p>
                        </Row>
                        <Row className={"row-margin"}>
                            <h4>SPONSORS</h4>
                            <p>{event ? event.getDescription() : ''}</p>
                        </Row>
                    </Col>
                </Row>
            </RowGray>
        </Container>
    )
}