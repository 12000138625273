import "../../App.css";
import "./Navigation.css";
import {Container, Nav, Navbar, NavDropdown} from "react-bootstrap";
import {useHistory, useLocation} from "react-router-dom";
import {useEffect, useState} from "react";
import logo from "../../media/logo.png";

export default function Navigation(props) {

    let history = useHistory();
    const location = useLocation();

    const [isScrolledTop, setIsScrolledTop] = useState(true);
    const [isScrolledTopFirstTime, setIsScrolledTopFirstTime] = useState(true);

    useEffect(() => {
        window.onscroll = function() {
            setIsScrolledTop(window.pageYOffset === 0);
            setIsScrolledTopFirstTime(false);
        };
    }, []);

    function navigate(path) {
        history.push(path);
    }

    function isActive(id) {
        let firstLevelPath = location.pathname.split("/")[1];
        console.log(firstLevelPath);
        return id === firstLevelPath;
    }

    const [show, setShow] = useState(false);
    const showDropdown = (e)=>{
        setShow(!show);
    }
    const hideDropdown = e => {
        setShow(false);
    }

    return (
        <Navbar id={"main-nav"}
                className={`
                    ${isScrolledTop ? "is-scrolled-top-bg" : "is-not-scrolled-top-bg"}
                    ${isScrolledTopFirstTime ? "page-load-animation-delay" : ""}
                `}
                expand={"lg"}>
            <Container style={{height: "100%", alignItems: "start", paddingTop: 10}}>
                <Navbar.Brand onClick={() => navigate("/")} style={{cursor: "pointer"}}>
                    <img
                        src={logo}
                        height={"40rem"}
                        className={`d-inline-block align-top 
                            ${isScrolledTop ? "is-scrolled-top-logo" : "is-not-scrolled-top-logo"}
                        `}
                        alt="React Bootstrap logo"
                    />
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="main-nav-collapse"/>
                <Navbar.Collapse id="main-nav-collapse">
                    <Nav className='m-auto'>
                        <Nav.Link active={isActive("")} onClick={() => navigate("/")}>
                            THE ALLIANCE
                        </Nav.Link>
                        <NavDropdown
                            show={show}
                            onMouseEnter={showDropdown}
                            onMouseLeave={hideDropdown}
                            active={isActive("categories")} title="CATEGORIES" id="nav-dropdown">
                            <NavDropdown.Item
                                onClick={() => navigate("/categories/art")}>ART</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => navigate("/categories/fashion")}>FASHION</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => navigate("/categories/beetle")}>BEETLE</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => navigate("/categories/griffon")}>GRIFFON</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => navigate("/categories/jumping-puzzles")}>JUMPING PUZZLES</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => navigate("/categories/music")}>MUSIC</NavDropdown.Item>
                            <NavDropdown.Item
                                onClick={() => navigate("/categories/pve")}>OPEN WORLD</NavDropdown.Item>
                        </NavDropdown>
                        <Nav.Link active={isActive("events")} onClick={() => navigate("/events")}>
                            EVENTS
                        </Nav.Link>
                        <Nav.Link active={isActive("about-us")} onClick={() => navigate("/about-us")}>
                           ABOUT US
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>
    )
}