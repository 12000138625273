
import {ApiRequest, doApiRequest, apiServiceClient} from './client'
import {GetEventRequest, ListEventRequest} from "uaot-api/grpc/uaot/api/v1/requests_pb";
import {GetEventResponse, ListEventResponse} from "uaot-api/grpc/uaot/api/v1/responses_pb";
import {ServiceError} from "uaot-api/grpc/uaot/api/v1/rpc_pb_service";
import { plainToInstance } from "class-transformer";
import {Event} from "uaot-api/grpc/uaot/api/v1/event_pb";


function getEvent(id: string): Promise<Event> {
    return new Promise<GetEventResponse>((resolve, reject) => {
        const request = new GetEventRequest();
        request.setEvent(id);

        const apiRequest: ApiRequest = {
            client: apiServiceClient,
            // @ts-ignore
            func: apiServiceClient.getEvents,
            request
        };

        doApiRequest(apiRequest).then((response: GetEventResponse) => {
            resolve(response);
        }).catch((error: ServiceError) => {
            reject(error);
        })
    }).then((e: GetEventResponse) => {
        return plainToInstance(Event, e.getEvent());
    })
}
function getEvents(search: string, page: number, excludeHistorical: boolean): Promise<Event[]> {
    return new Promise<ListEventResponse>((resolve, reject) => {
        const request: ListEventRequest = new ListEventRequest();
        request.setSearch(search);
        request.setPage(page);
        request.setExcludeHistorical(excludeHistorical)

        const apiRequest: ApiRequest = {
            client: apiServiceClient,
            // @ts-ignore
            func: apiServiceClient.listEvents,
            request
        };

        doApiRequest(apiRequest).then((response: ListEventResponse) => {
            resolve(response);
        }).catch((error: ServiceError) => {
            reject(error);
        })
    }).then((e: ListEventResponse) => {
        return e.getEventList().map((eventJson: any) => plainToInstance(Event, eventJson));
    })
}
export {getEvent, getEvents}
