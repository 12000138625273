import "./Music.css";
import "./../../App.css";
import {Col, Container, Image, Row} from "react-bootstrap";
import IFrame from "../../component/IFrame/IFrame";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import music1 from "../../media/music/music1.png"
import music2 from "../../media/music/music2.jpg"
import music3 from "../../media/music/music3.jpg"
import musicTitle from "../../media/titles/music.png";

export default function Music() {
    return (
        <Container fluid style={{padding: 0}}>
            <RowBackground title img={music1} borderBottom>
                <h1>MUSIC</h1>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Where words leave off, music begins.</h3>
                                Some individuals have made it their ultimate endgame to bring joy to the player-base by
                                using in-game instruments. Sometimes they play solo, as a duet or even as a whole
                                organized group! You can purchase instruments that are categorized as "toys" in your
                                novelties tab in the hero panel. You equip them and are presented with a multitude of
                                notes and octaves which replace your normal skills.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={music2} borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Learning to play</h3>
                                Learning how to play these instruments is very doable, and is much more achievable than
                                you might think. You can play alone, try to coordinate with friends or play in a band.
                                The instruments are tuned to major scales, so you will only have seven unique notes to
                                play at a time. Basically like having no black notes on a piano. You will need to get
                                used to swapping octaves around, and also try to adjust to the delay/lag in game when
                                you play these instruments. Swapping between octaves quickly is key to making music
                                sound constant and fluid, but that takes time and experience to get used to. You can
                                play multiple notes at once, allowing the playability of chords which gives the music
                                much more depth.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Common notation </h3>
                                There is a common way of writing down notes for Guild Wars which are written in a form
                                of a tab/tablature. You will usually come across numbers for notes from 1 to 8 that
                                appear in round brackets, which means they are played in the high octave, and square
                                brackets for low. Numbers outside brackets are typically played in the middle octave.
                            </p>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={music3}>
                <Container>
                    <Row>
                        <Col md={6} style={{margin: 0}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Concerts</h3>
                                Of course, if you aren't too excited to play the instruments yourself, you can always
                                just listen to others play, which is a very common occurrence in Divinity’s Reach or
                                just join concerts that are organized on some occasions. With our associated guilds and
                                streamers, United Arts is a place where you can request, organize, advertise and/or
                                broadcast your concerts!
                            </p>
                        </Col>
                        <Col md={6} style={{margin: 0}}>
                            <IFrame style={{border: "solid 3px #E5B178"}}
                                    src="https://www.youtube.com/embed/2uhZNzBVpds?start=137"
                                    title="YouTube video player" frameBorder="0"
                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                    allowFullScreen/>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
        </Container>
    )
}