import "./Jumping.css";
import "./../../App.css";
import {Col, Container, Image, Row} from "react-bootstrap";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import ImageSlide from "../../component/ImageSlide/ImageSlide";
import ImageC from "../../component/Image/ImageC";
import jp1 from "../../media/jumping-puzzle/jp1.jpg";
import jp2 from "../../media/jumping-puzzle/jp2.jpg";
import jp3 from "../../media/jumping-puzzle/jp3.jpg";
import jumping1 from "../../media/jumping-puzzle/jumping1.jpg";
import jumping2 from "../../media/jumping-puzzle/jumping2.jpg";
import jumping3 from "../../media/jumping-puzzle/jumping3.jpg";
import jumping4 from "../../media/jumping-puzzle/jumping4.jpg";
import jumping5 from "../../media/jumping-puzzle/jumping5.jpg";
import experts1 from "../../media/jumping-puzzle/experts1.jpg";
import experts2 from "../../media/jumping-puzzle/experts2.png";
import experts3 from "../../media/jumping-puzzle/experts3.jpg";
import experts4 from "../../media/jumping-puzzle/experts4.jpg";
import custom1 from "../../media/jumping-puzzle/custom1.jpg";
import custom2 from "../../media/jumping-puzzle/custom2.jpg";
import custom3 from "../../media/jumping-puzzle/custom3.jpg";
import custom4 from "../../media/jumping-puzzle/custom4.png";
import sab1 from "../../media/jumping-puzzle/sab1.jpg";
import jumpingTitle from "../../media/titles/jps.png";
export default function Jumping() {

    let imagesJumping = [jumping1, jumping2, jumping3, jumping4, jumping5];
    let imagesExperts = [experts1, experts2, experts3, experts4];
    let imagesCustom = [custom1, custom2, custom3, custom4];

    return (
        <Container fluid style={{padding: 0}}>
            <RowBackground title img={jp1} borderBottom>
                <h1>JUMPING PUZZLES</h1>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={6} style={{margin: 0, paddingBottom: "5%", textAlign: "left"}}>
                            <h3 style={{marginBottom: 20}}>Core game Jumping Puzzles</h3>
                            Jumping Puzzles had been a great activity since the release of the core Guild Wars 2 game,
                            as they were a way of showing the incredible freedom of movement compared to other MMOs at
                            the time. There are a ton of fun small jumping puzzles spread across all the core Tyria
                            maps, HoT and also in the living world 3 maps.
                        </Col>
                        <Col md={6}>
                            <ImageSlide images={imagesJumping}/>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={jp2} borderBottom>
                <Container>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={6}>
                                    <ImageSlide images={imagesExperts}/>
                                </Col>
                                <Col md={6} style={{
                                    textAlign: "left",
                                    minHeight: 200,
                                    marginBottom: 20
                                }}>
                                    <h3 style={{marginBottom: 20}}>The JP experts</h3>
                                    Some JPs have reached a status of being “feared” within the community for being
                                    hard, unfair
                                    or even badly designed - so we founded a <b>guild that ports or even gladly guides
                                    people</b> through all the JPs in the game. <br/>
                                    Also, our JP nerds are more than happy to <b>teach you different basic and advanced
                                    jump techniques!</b> You would be surprised how technical jumping can get! We also
                                    love
                                    to apply those techniques to get through JPs fast without mounts. We are putting a
                                    lot of effort into finding skips and using all the skills and best builds to reach
                                    certain spots.
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
            <RowGray borderBottom>
                <Container>
                    <Row>
                        <Col md={12} style={{textAlign: "left", marginBottom: 20}}>
                            <h3>Custom guild hall JPs</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <Row>
                                <Col md={6} style={{
                                    textAlign: "left",
                                    minHeight: 200,
                                    marginBottom: 20
                                }}>
                                    However, after the introduction of mounts with Path of Fire, there were - besides
                                    the Grothmar Valley jumping puzzle - <b>no new additions to the jumping puzzle
                                    genre.</b>
                                    Most existing jumping puzzles are sadly cheatable with mounts nowadays (even though
                                    they introduced the no mounts debuff in a small area around the JPs) and the joy of
                                    finding new jumping puzzles or the challenge of actually hard JPs is missing.
                                    Since we love jumping and being creative, we embrace the possibilities of creating
                                    our own content for JP fans in <b>custom made</b>
                                </Col>
                                <Col md={6} style={{textAlign: "left", marginBottom: 20}}>
                                    <b>guild hall JPs</b> on which we also hold
                                    regular jumping events where you can win prizes or just re-live the feeling of
                                    exploring fresh JPs again!<br/>
                                    Our goal is to <b>provide a big portfolio of custom made JPs</b> with a rating
                                    system so
                                    you can easily choose which ones you want to visit or try out at your own pace. If
                                    you have built an amazing JP in your hall or know someone who has done so, let us
                                    know! We are always happy to present them here and make the custom content available
                                    to a wider audience! Guild hall JPs shouldn’t slumber in their hall unvisited!!
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    <Row className={"justify-content-center"}>
                        <Col md={12} style={{marginBottom: 20}}>
                            <h1>~</h1>
                        </Col>
                    </Row>
                    <Row className={"justify-content-center"}>
                        <Col md={6}>
                            <ImageSlide images={imagesCustom}/>
                        </Col>
                    </Row>
                </Container>
            </RowGray>
            <RowBackground img={jp3} borderBottom>
                <Container>
                    <Row>
                        <Col md={6} style={{margin: 0, paddingBottom: "5%"}}>
                            <p style={{textAlign: "left"}}>
                                <h3 style={{marginBottom: 20}}>Annual Super Adventure Box Event</h3>
                                The <b>Super Adventure Box</b> each year is a great opportunity for the more dedicated jumpers
                                to come together and test their skills in the tribulation mode or also just have fun in
                                the normal modes of the event. We also hold an <b>annual speed run competition for SAB</b>
                                tribulation mode zones.
                            </p>
                        </Col>
                        <Col md={6}>
                            <ImageC fluid src={sab1} modal/>
                        </Col>
                    </Row>
                </Container>
            </RowBackground>
        </Container>
    )
}