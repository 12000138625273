import "./AboutUs.css";
import "./../../App.css";
import {Col, Container, Image, Row} from "react-bootstrap";
import RowBackground from "../../component/Rows/RowBackground/RowBackground";
import RowGray from "../../component/Rows/RowGray/RowGray";
import art1 from "../../media/art/art1.jpg";
import kenny from '../../media/about-us/kenny.png';
import nyoko from '../../media/about-us/nyoko.png';
import nemain from '../../media/about-us/nem.png';
import reaper from '../../media/about-us/reaper.png';
import rei from '../../media/about-us/rei.png';


import artTitleImage from "../../media/titles/art.png";
import TeamCard from "../../component/TeamCard/TeamCard";

export default function AboutUs() {

    return (
        <Container fluid style={{padding: 0, paddingTop: 10}}>
            <RowBackground title img={art1} borderBottom>
                <h1>ABOUT US</h1>
            </RowBackground>
            <RowGray>
                <Row className={'flex-row'}>
                    <Col md={4}>
                        <TeamCard userId={'deathblade'} image={kenny} name={'Deathblade'}>
                            <p>Networking</p>
                            <p>Communication</p>
                            <hr/>
                            <p>The fashion overlord in the flesh. Makes puns and goes to the
                                gym. Loves to bring people together. He eats about 2.5kg of food in a day. Probably more.
                            </p>
                        </TeamCard>
                    </Col>
                    <Col md={4}>
                        <TeamCard userId={'nyoko'} image={nyoko} name={'Nyoko'}>
                            <p>Organization</p>
                            <p>Information Management</p>
                            <hr/>
                            <p>Jumping puzzle king. He likes Bibimbap. He jumps and laughs at your face. Mario is
                                jealous. Spreadsheet warrior, eats Excel cells like cornflakes.</p>
                        </TeamCard>
                    </Col>
                    <Col md={4}>
                        <TeamCard userId={'nemain'} image={nemain} name={'Nemain'}>
                            <p>Social Media</p>
                            <p>Illustrations</p>
                            <hr/>
                            A Social Media nerd that is fueled by white Monster Energy drinks and Metal music. Loves
                            Griffon flying, hard instanced content and Fashion Wars. Expert in taking 2 hour long naps.
                        </TeamCard>
                    </Col>
                </Row>
                <Row className={'flex-row'}>
                    <Col md={4}>
                        <TeamCard userId={'reaper'} image={reaper} name={'Reaper'}>
                            <p>Consultant</p>
                            <hr/>
                            <p>
                                Part time philosopher that is pretty decent at Beetle racing. Discovered his passion for
                                fishing and speedboating with EoD. Too modest for this world. Rare green Pokémon.
                            </p>
                        </TeamCard>
                    </Col>
                    <Col md={4}>
                        <TeamCard userId={'rei'} image={rei} name={'Rei'}>
                            <p>Artist Representative</p>
                            <hr/>
                            <p>Drifts Griffons and flies Beetles. She will beat you at both. Famous aerial-perspective
                                (Griffoncam) in Beetle races. Amazing artist that is too humble for her own good. Nice
                                taste in music.
                            </p>
                        </TeamCard>
                    </Col>
                </Row>
            </RowGray>
        </Container>
    )
}