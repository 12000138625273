import "./RowLightGray.css";
import "../SpecialRow.css";
import {Container} from "react-bootstrap";

export default function RowLightGray(props) {
    return (
        <div className={`special-row row-light-gray 
            ${props.borderTop ? "golden-line-top" : ""} 
            ${props.borderBottom ? "golden-line-bottom" : ""}
        `}>
            <Container>
                {props.children}
            </Container>
        </div>
    )
}