import "../SpecialRow.css"
import {Container} from "react-bootstrap";

export default function RowBackground(props) {
    return (
        <div className={`special-row 
            ${props.title ? "title" : ""}
            ${props.borderTop ? "golden-line-top" : ""} 
            ${props.borderBottom ? "golden-line-bottom" : ""}
            ${props.lastRow ? "max-height" : ""}
        `}
         style={{backgroundImage: `url(${props.img})`, backgroundSize: "cover",
             backgroundPosition: `${props.position ? props.position : "center"}`}}>
            <Container>
                {props.children}
            </Container>
        </div>
    )
}