import './TeamCard.css';
import '../../util/variables.css';
import {Button, Card} from "react-bootstrap";
import React from "react";
import {useHistory} from "react-router-dom";
import H from 'history';

interface TeamCardProps {
    userId: string,
    image: string,
    name: string,
    children?: React.ReactNode
}

export default function TeamCard (props: TeamCardProps) {

    let history = useHistory<H.LocationState>();
    console.log(props.userId);

    return (
        <Card onClick={() => history.push('/about-us/' + props.userId)} className={'team-card'}>
            <div className={'team-card-background'}>
            </div>
            <Card.Body className={'team-card-body'}>
                <div>
                    <img className={'team-card-image'} src={props.image} alt={'team-card'}/>
                </div>
                <Card.Title className={'team-card-title'}>{props.name}</Card.Title>
                <Card.Text style={{fontSize: 15, textAlign: 'center'}}>
                    {props.children}
                </Card.Text>
                <div style={{display: 'inline-block'}}/>
            </Card.Body>
            <Button className={'team-card-button'} variant="primary">Learn more</Button>
        </Card>
    )
}