import "./Testimonial.css";
import {Col, Image, Row} from "react-bootstrap";

interface TestimonialProps {
    quote: string;
    quoteBy: string;
    avatar: string;
}

export default function Testimonial(props: TestimonialProps) {

    return (
        <Row>
            <Col xs={2}>
                <Image className='avatar' fluid src={props.avatar}/>
            </Col>
            <Col>
                <p style={{textAlign: "left"}}>
                    <i>"{props.quote}"</i>
                </p>
                <p style={{textAlign: "left", padding: 10, marginBottom: 30}}>
                    - {props.quoteBy}
                </p>
            </Col>
        </Row>
    )
}